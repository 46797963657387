<template>
  <div>
    <solution-header
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :call-us-btn="callUsBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`供应链金融实践中遇到的痛点`"
               :en_title="`Pain points encountered in
               the practice of supply chain finance`"></Paragraph>

    <Titlestrlist :datalist="datalistF" style="margin-bottom: 6rem"></Titlestrlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`解决方案`"
               :en_title="`Solution`"></Paragraph>
    <div style="width: 80%;margin-left: 10%">
    <Titlestrlist :datalist="datalistFX" style="margin-bottom: 6rem"></Titlestrlist>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/star.svg`)"
               :title="`方案优势`"
               :en_title="`Solution advantage`"></Paragraph>
    <div style="width: 70%;margin-left: 15%;display: flex;flex-direction: row;margin-bottom: 4rem">
    <div style="width: 50%;font-weight: bold;color:#228BFC;font-size: 1.6rem">
      <ul style="list-style: none">
        <li style="padding: 1rem">
          可利于规避产业链周期性资金风
        </li>
        <li>
          险，实现可持续、高质量发展
        </li>
      </ul>
    </div>
      <div style="width: 50%;font-weight: bold;color:#228BFC;font-size: 1.6rem">
        <ul style="list-style: none">
          <li style="padding: 1rem">
            有利于增强核心企业信用，改善内
          </li>
          <li>
            部管理，提升产业链整体竞争力
          </li>
        </ul>
      </div>
    </div>
    <div style="width: 70%;margin-left: 15%;display: flex;flex-direction: row;margin-bottom: 4rem">
      <div style="width: 50%;font-weight: bold;color:#228BFC;font-size: 1.6rem">
        <ul style="list-style: none">
          <li style="padding: 1rem">
            可拆分、流转，
          </li>
          <li>
            实现核心企业信用跨级传递
          </li>
        </ul>
      </div>
      <div style="width: 50%;font-weight: bold;color:#228BFC;font-size: 1.6rem">
        <ul style="list-style: none">
          <li style="padding: 1rem">
            可按需拆分融资，
          </li>
          <li>
            降低上游供应商融资财务成本
          </li>
        </ul>
      </div>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景及案例`"
               :en_title="`Application scenarios and cases`"></Paragraph>
    <div style="font-size: 2rem;color:#228BFC;font-weight: bold">农业金融服务平台</div>
    <div style="width: 80%;margin-left: 10%;margin-bottom: 6rem;margin-top: 2rem">
      <img src="@/assets/image/solution/financial/pagex.png" style="width: 100%">
    </div>
  </div>
</template>

<script>
import SolutionHeader from '@/components/common/SolutionHeader';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    SolutionHeader,
    // ImageList,
    Paragraph,
    Titlestrlist,
  },
  data() {
    return {
      datalistF: [
        { title: '>', content: '供应链上存在信息孤岛' },
        { title: '>', content: '核心企业信用不能传递' },
        { title: '>', content: '缺乏可信的贸易场景' },
        { title: '>', content: '履约风险无法有效控制' },
        { title: '>', content: '融资难融资贵' },
      ],
      datalistFX: [
        {
          title: '建立共同信息平台，打通信息孤岛\n',
          content: '区块链作为分布式账本技术的一种，集中维护一个分布式共享账本，'
              + '使得非商业机密数据在所有节点间存储、共享、让数据在链上实现可信流转，'
              + '极大地解决了供应链金融业务中的信息孤岛问题。',
        },
        { title: '确权凭证上链拆分流转，传递核心企业信用\n', content: '登记在区块链上的可流转、可融资的确权凭证，使核心企业信用能沿着可信链路传递，解决了核心企业信用不能向多级供应商传递的问题。一级供应商对核心企业签发的凭证进行签收之后，可根据实际贸易背景将其拆分、流转给上一级供应商，而在拆分、流转过程中，核心企业的背书效用不变，整个凭证的拆分、流转过程可溯源。' },
        { title: '交易数据融合及上链，打造丰富可信的贸易场景\n', content: '在区块链架构下，系统可对供应链中贸易参与方的行为进行约束，进而对相关的交易数据融合及上链，形成线上化的基础合同、单证、支付等结构严密、完整的记录，以佐证贸易行为的真实性。银行的融资服务可以覆盖到核心企业及其一级供应商之外的供应链上其它中小企业。在丰富可信的贸易场景的同时，大大降低了银行的参与成本。' },
        { title: '智能合约助力约定自动执行，防范履约风险\n', content: '智能合约是一个区块链上合约条款的计算机程序，在满足执行条件时可自动执行。智能合约的加入，确保了贸易行为中交易双方或多方能够如约履行义务，使交易顺利可靠地进行。机器信用的效率和可靠性，极大地提高了交易双方的信任度和交易效率，并有效地管控履约风险，是一种交易制度上的创新。\n' },
        { title: '贸易行为真实性存证及核心企业信用共享，实现融资降本增效\n', content: '在区块链技术与供应链金融的结合下，上下游的中小企业可以更高效地证明贸易行为的真实性，并共享核心企业信用，可以在积极响应市场需求的同时满足对融资的需求，从根本上解决供应链上“融资难、融资贵”的问题，实现核心企业的“去库存”的目的，并达到“优化供给侧”的目标，从而提高整个供应链上资金运转效率。\n' },
      ],
      title: '区块链+金融解决方案',
      body:
          '供应链金融是以核心客户为依托，以真实贸易背景为前提，运用自偿性贸易融资的方式，'
          + '通过应收账款质押登记、第三方监管等专业手段封闭资金流或控制物权，对供应链上下游企业提供的综合性金融产品和服务。'
          + '而处在供应链上游的中小企业供应商由于其自身很难证明其与核心企业的贸易关系导致融资难、融资贵、融资慢。结合区块链技术，'
          + '可以很好地解决传统供应链金融面监的一些问题。',
      image: require('@/assets/image/solution/financial/financial.svg'),
      showDetailBtn: true,
      callUsBtn: true,
      images: [
        require('@/assets/image/solution/financial/1.jpg'),
        require('@/assets/image/solution/financial/2.jpg'),
        require('@/assets/image/solution/financial/3.jpg'),
        require('@/assets/image/solution/financial/4.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
